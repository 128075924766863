import { JSX }				from "preact";
import { useEffect }		from "preact/hooks";

import * as common			from "@glas/shared/common";

import * as actions			from "@glas/frontend/actions";
import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { ParsedText }		from "@glas/frontend/components/ParsedText";

type CollectionDetailProps = Readonly<{
	collection:	common.ExtendedCollection | null,
	path:		string,
}>;

type ExtendedProps = CollectionDetailProps & Readonly<{	collectionId: string }>;

export const CollectionDetail = (props: CollectionDetailProps):JSX.Element => {
	const collection	= props.collection;

	// preact-router does not allow us to include path parameters in our props
	const extendedProps	= props as ExtendedProps;
	const collectionId	= common.parseCollectionId(extendedProps.collectionId);

	useEffect(
		() => {
			if (collectionId !== null)   actions.getExtendedCollection(collectionId);
		},
		[ collectionId ]
	);

	return (
		collection !== null
		?	<div class="collection-detail">
				<h1>{collection.title}</h1>
				<span class="collection-description adoptFormat">
					<ParsedText text={collection.descShort} />
				</span>
				{ collection.objectThumbnails.length === 0 ||
					<div class="collection-objects">
						<h2>Teile der Kollektion</h2>
						<ObjectThumbnails thumbnails={collection.objectThumbnails} />
					</div>
				}
				<span class="collection-description adoptFormat">
					<ParsedText text={collection.description} />
				</span>
			</div>
		:	<div class="collection-detail">Die angefrage Kollektion ist nicht verfügbar.</div>
	);
};
