import { Endo }			from "ts-base/function";
import * as NotNulls	from "ts-base/notNull";

import * as common		from "@glas/shared/common";

import { Model }		from "@glas/frontend/model";
import * as url			from "@glas/frontend/url";

export type Refresh<T> = (change:Endo<T>) => void;

// hack: provided someone called connectRefresh before refresh is actually used, this cannot fail
// forget to call setRefresh and you're in deep shit. you'll notice quite early, though.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let refresh:Refresh<Model>	= null as any;
export const setRefresh	= (it:Refresh<Model>):void => { refresh = it; };

//-------------------------------------------------------------------
//	BOOT
//-------------------------------------------------------------------

export const boot = ():void => {
	getDropDownTexts();
	getIntroduction();
	getCollections();
	getExhibition();
	getObjects(common.emptySearchOptions);
};

const getDropDownTexts	= ():void => {
	fetch("/api/dropDownTexts")
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((json:common.DropDownTexts) =>
		refresh(model => ({
			...model,
			dropDownTexts: json,
		}))
	)
	.catch(err => console.error("Could not load drop down texts, ", err));
};

const getIntroduction = ():void => {
	fetch("/api/introduction")
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((introduction:ReadonlyArray<common.IntroductionParagraph>) =>
		refresh(model => ({
			...model,
			introduction: introduction,
		}))
	)
	.catch(err => console.error("Could not load introduction", err));
};

const getCollections	= ():void => {
	fetch("/api/collections")
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((json:ReadonlyArray<common.Collection>) =>
		refresh(model => ({
			...model,
			collections: json,
		}))
	)
	.catch(err => console.error("Could not load collections, ", err));
};

const getExhibition = ():void => {
	fetch("/api/exhibition")
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((exhibition:common.ExtendedExhibition) =>
		refresh(model => ({
			...model,
			exhibition: exhibition,
		}))
	)
	.catch(err => console.error("Could not load exhibition", err));
};

//-------------------------------------------------------------------
//	OBJECT
//-------------------------------------------------------------------

export const getObjects = (searchOptions:common.ExtendedSearchOptions):void => {
	fetch(`/api/db?${searchUrl(searchOptions)}`)
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((objects:common.ExtendedSearchResults) =>
		refresh(model => ({
			...model,
			objects: objects,
		}))
	)
	.catch(err => console.error("Could not load objects, ", err));
};

export const getExtendedObject = (inventoryId:common.InventoryId):void => {
	const query	= url.queryString({
		inventoryId:	inventoryId.toString(),
	});

	fetch(`/api/object${query}`)
	.then(res => res.status === 200
		? res.json()
		: null)
	.then((object:common.ExtendedObject|null) =>
		refresh(model => ({
			...model,
			object: object,
		}))
	)
	.catch(err => console.error(`Could not load extended object (inventoryId=${inventoryId})`, err));
};

export const resetObject	= ():void => {
	refresh(model => ({
		...model,
		searchOptions:		{
			...model.searchOptions,
			currentResultPage:	0,
		},
		object: null,
	}));
};

//-------------------------------------------------------------------
//	COLLECTION
//-------------------------------------------------------------------

export const getExtendedCollection	= (collectionId:common.CollectionId):void => {
	const query	= url.queryString({
		collectionId:	collectionId.toString(),
	});

	fetch(`/api/collection${query}`)
	.then(res => res.status === 200
		? res.json()
		: null)
	.then((collection:common.ExtendedCollection|null) =>
		refresh(model => ({
			...model,
			collection: collection,
		}))
	)
	.catch(err => console.error(`Could not load extended collection (collectionId=${collectionId})`, err));
};

export const resetCollection	= ():void => {
	refresh(model => ({
		...model,
		collection: null,
	}));
};

//-------------------------------------------------------------------
//	EXHIBITION
//-------------------------------------------------------------------

export const getExtendedExhibitionBoard	= (boardId:common.ExhibitionBoardId):void => {
	const query	= url.queryString({
		boardId:	boardId.toString(),
	});

	fetch(`/api/exhibitionBoard${query}`)
	.then(res => res.status === 200
		? res.json()
		: null)
	.then((exhibitionBoard:common.ExtendedExhibitionBoard|null) =>
		refresh(model => ({
			...model,
			exhibitionBoard: exhibitionBoard,
		}))
	)
	.catch(err => console.error(`Could not load extended exhibition board (boardId=${boardId})`, err));
};

export const resetExhibitionBoard	= ():void => {
	refresh(model => ({
		...model,
		exhibitionBoard: null,
	}));
};

//-------------------------------------------------------------------
//	SEARCH
//-------------------------------------------------------------------

export const resetSearch	= ():void => {
	refresh(model => ({
		...model,
		searchOptions:		{
			...model.searchOptions,
			currentResultPage:	0,
		},
		searchMore: false,
	}));
};

export const getSearchResult = (searchOptions:common.ExtendedSearchOptions):void => {
	fetch(`/api/db?${searchUrl(searchOptions)}`)
	.then(res => res.status === 200
		? res.json()
		: [])
	.then((searchResults:common.ExtendedSearchResults) =>
		refresh(model => ({
			...model,
			searchResults: searchResults,
		})
	))
	.catch(err => console.error("Could not load thumbnails, ", err));
};

export const resetSearchOptions	= ():void => {
	refresh(model => ({
		...model,
		searchOptions:		{
			...common.emptySearchOptions,
			currentResultPage:	0,
		},
	}));
};

export const setCurrentResultPage = (page:number):void => {
	refresh(model => ({
		...model,
		searchOptions:		{
			...model.searchOptions,
			currentResultPage:	page,
		},
	}));
};

export const setSearchOption = <K extends keyof common.SearchOptions>(key:K, value:common.SearchOptions[K]):void => {
	refresh(model => ({
		...model,
		searchOptions:		{
			...model.searchOptions,
			[key]:	value,
		},
	}));
};

//-------------------------------------------------------------------

const searchUrl = (searchOptions:common.ExtendedSearchOptions):string =>
	Object.entries(searchOptions)
		.map(([ k,v ]) => v !== null ? [ k, v.toString() ] : null)
		.filter(NotNulls.is)
		.map(kv => kv.map(encodeURIComponent).join("="))
		.join("&");
