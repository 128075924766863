import * as Objects	from "ts-base/object";
import * as Arrays	from "ts-base/array";

import * as common	from "@glas/shared/common";

export const getCategories = (categoryList:common.CategoryList):ReadonlyArray<common.Category> =>
	Objects.unsafeTypedKeys(categoryList);

export const getObjectDescriptionsForCategory = (categoryList:common.CategoryList, category:common.Category):ReadonlyArray<common.Objectdesc> =>
	Objects.unsafeTypedKeys(categoryList[category]);

export const getTypesForObjectDescription = (categoryList:common.CategoryList, objectdesc:common.Objectdesc):ReadonlyArray<common.Type> =>
	Arrays.flatMap(Object.values(categoryList))(it => it[objectdesc] ?? []);

export const formatInventoryId = (inventoryId:common.InventoryId):string =>
	// remove leading location prefix from inventoryId
	inventoryId.replace(/^[A|F|R|S]_/, "");

export const classes	= (...names:ReadonlyArray<string|boolean>):string =>
	names.filter(it => it !== false && it !== true).join(" ");
