import { Branded } from "ts-base/branded";

export const regexFourDigitNumber		= /^\d{4}/;
export const regexTwoDecimalFloat		= /^(0|[1-9]\d*)?(\.\d{1,2})?/;
export const regexTwoDecimalFloatComma	= /^(0|[1-9]\d*)?(,\d{1,2})?/;

// zero or positive
export type NaturalNumber	= Branded<number, "NaturalNumber">;
export const NaturalNumber	= Branded.brand<NaturalNumber>;

export const parseNaturalNumber	= (s:string):NaturalNumber|null =>
	/^(0|[1-9][0-9]*)$/.test(s) ? NaturalNumber(parseInt(s)) : null;

export type FourDigitNaturalNumber	= Branded<number, "FourDigitNaturalNumber">;
export const FourDigitNaturalNumber	= Branded.brand<FourDigitNaturalNumber>;

export const parseFourDigitNumber	= (s:string):FourDigitNaturalNumber|null =>
	regexFourDigitNumber.test(s) ? FourDigitNaturalNumber(parseInt(s)) : null;

// positive float
export type Float	= Branded<number, "Float">;
export const Float	= Branded.brand<Float>;

export const parseTwoDecimalFloat	= (s:string):Float|null =>
	regexTwoDecimalFloat.test(s) ? Float(parseFloat(s)) : null;

export type NonEmptyString	= Branded<string, "NonEmptyString">;
export const NonEmptyString	= Branded.brand<NonEmptyString>;

export const parseNonEmptyString	= (s:string):NonEmptyString|null =>
	s !== "" ? NonEmptyString(s) : null;

// YYYY-MM-DD
export type IsoDate			= Branded<string, "IsoDate">;
export const IsoDate		= Branded.brand<IsoDate>;

export const parseIsoDate	= (s:string):IsoDate|null =>
	/^\d{4}-\d{2}-\d{2}$/.test(s) ? IsoDate(s) : null;
