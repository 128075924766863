import { JSX }				from "preact";
import { Link }				from "preact-router/match";

import * as common			from "@glas/shared/common";

import { formatInventoryId } from "@glas/frontend/util";

type ObjectThumbnailProps = Readonly<{
	thumbnails: ReadonlyArray<common.ObjectThumbnail> | undefined,
}>;

export const ObjectThumbnails = (props:ObjectThumbnailProps):JSX.Element =>
	<div>
		<ul class="thumbnails">
			{ props.thumbnails?.map((object:common.ObjectThumbnail) =>
				<li class="thumbnail-item">
					<Link activeClassName="active" href={"/object/" + window.encodeURIComponent(object.inventoryId)}>
						<div class="image-border thumbnail-image">
							<img class="image"
								alt={object.imageFile !== null
									? `Foto von ${formatInventoryId(object.inventoryId)}`
									: `Kein Foto für ${formatInventoryId(object.inventoryId)} verfügbar`}
								src={object.imageFile !== null
									? "/img/180/" + window.encodeURIComponent(object.imageFile)
									: "/img/NoImageFound_180.png"}/>
						</div>
						<div>
							<span class="thumbnail-title">{object.titleShort}</span>
							<span class="thumbnail-description">{object.datingShort}</span>
						</div>
					</Link>
				</li>)
			}
		</ul>
	</div>;
