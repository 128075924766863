import { JSX }							from "preact";

import * as common						from "@glas/shared/common";

import { ExhibitionBoardThumbnails }	from "@glas/frontend/pages/exhibition/ExhibitionBoardThumbnails";
import { ParsedText }					from "@glas/frontend/components/ParsedText";

type ExhibitionOverviewProps = Readonly<{
	exhibition:	common.ExtendedExhibition | null,
	path:			string,
}>;

export const ExhibitionOverview = (props: ExhibitionOverviewProps):JSX.Element => {
	const exhibition:common.ExtendedExhibition | null = props.exhibition;

	// {exhibition.description}

	return (
		exhibition !== null
		?	<div class="exhibition-overview">
				<div class="exhibition-teaser">
					<img class="exhibition-image" src="/img/Test.jpg"></img>
					<div class="exhibition-teaser-text">
						<h1 class="exhibition-title">{exhibition.title}</h1>
						<h2 class="exhibition-subtitle">27. März - 20. November 2022</h2>
						<div class="exhibition-introduction">
							<div>
								<span class="exhibition-description adoptFormat">
									Die Herstellung von Glas, bekannt seit der Antike, entwickelte sich in der Frühen Neuzeit zu einer Hochtechnologie. An verschiedenen Orten Europas entstanden Zentren der Glasherstellung.Auch der Thüringer Wald war wegen der Holzvorkommen ein idealer Standort für Glashütten. Das weitgehend geheime Wissen um die Inhaltsstoffe der Glasmasse und die Fertigungstechniken befand sich in der Hand weniger Familien. Dies änderte sich mit der Verschriftlichung der Vorgehensweisen im ausgehenden 17. Jahrhundert.
									Die Grafen und Fürsten von Schwarzburg vergaben Privilegien für den Betrieb von Glashütten. Sie profitierten von den daraus erzielten Steuern und Zöllen und versorgten zugleich ihre eigenen Höfe in Arnstadt, Rudolstadt und Sondershausen mit Glas. In diesen Hütten entstand Hohl- und Flachglas nicht nur aus grünem Thüringer Waldglas, sondern in vielen verschiedenen Qualitäten und Farben. Das Sortiment reichte dabei von einfachen bis zu luxuriösen Gegenständen für den alltäglichen Gebrauch wie auch für besondere Anlässe. Glas war wegen seiner ungewöhnlichen Transparenz begehrt und kostbar.
								</span>
							</div>
						</div>
					</div>
				</div>
				<div>
					<h2 class="exhibition-locations-title">Eine Ausstellung - Vier Museen</h2>
					<span class="exhibition-locations">Die Ausstellung präsentiert an den vier Standorten Glasobjekte aus dem ehemaligen Besitz der Grafen und Fürsten von Schwarzburg sowie Grabungsfunde unter verschiedenen Aspekten der Zusammensetzung, Herstellung, Veredelung, Nutzung und Bedeutung.
					Das Glas der Schwarzburger ist das Ergebnis der Forschungen des Verbundprojekts »Glas in Thüringen zwischen 1600 und 1800« (Justus-Liebig-Universität Gießen, Kulturbetrieb der Stadt Arnstadt und TU Bergakademie Freiberg) und entstand in Kooperation mit dem Schwarzburger Museumsverbund. Die Ausstellung besteht aus vier Teilen, die an den vier Orten und als digitale Ausstellung besucht werden kann.
					</span>
					<ul class="exhibition-location-list">
						<li class="exhibition-location">
							<div class="exhibition-location-image">
								<img class="kanzeluhr" src="/img/Kanzeluhr.jpg" alt="Glas wandert!"/>
							</div>
							<div>
								<h2 class="exhibition-location-teaser">Glas wandert!</h2>
								<div class="exhibition-location-date">27.3. - 29.5.2022</div>
								<div class="exhibition-location-institution">Regionalmuseum Bad Frankenhausen</div>
								<div class="exhibition-location-text">
									Die Ausstellung in Bad Frankenhausen zeigt die Herstellung, den Handel und die Verarbeitung von Glas. Glasmacher reisten oft weite Strecken von ihrem Herkunftsort zur Glashütte. Die Glasergesellen begaben sich auf Wanderung. Glas musste behutsam transportiert werden und diente als Transportbehältnis. Die Kanzeluhr steht für das Handwerk rund um Glas.
								</div>
							</div>
						</li>
						<li class="exhibition-location">
							<div>
								<h2 class="exhibition-location-teaser">Glas verändert!</h2>
								<div class="exhibition-location-date">3.4. - 30.9.2022</div>
								<div class="exhibition-location-institution">Schlossmuseum Sondershausen</div>
								<div class="exhibition-location-text">
									Die Ausstellung in Sondershausen dreht sich um Glas als Material der sozialen Eliten. Der Schwarzburger Willkomm symbolisiert dabei höﬁ sche Trinkrituale. Einen Glanzpunkt stellt die Goldene Kutsche dar, deren Verglasung die Schwarzburger bei ihren Fahrten sichtbar machte und schützte. Ein zweites Thema beleuchtet die Innovationskraft von Glas.
								</div>
							</div>
							<div class="exhibition-location-image">
								<img class="humpen" src="/img/Humpen.jpg" alt="Glas verändert!"/>
							</div>
						</li>
						<li class="exhibition-location">
							<div class="exhibition-location-image">
								<img class="krokodil" src="/img/Krokodil.jpg" alt="Glas schützt!"/>
							</div>
							<div>
								<h2 class="exhibition-location-teaser">Glas schützt!</h2>
								<div class="exhibition-location-date">6.5. - 3.10.2022</div>
								<div class="exhibition-location-institution">Thüringer Landesmuseum Heidecksburg Rudolstadt</div>
								<div class="exhibition-location-text">
									Der Schwerpunkt der Ausstellung in Rudolstadt liegt auf verschiedenen Aspekten von Glas als Sammlungsgegenstand und auf seiner Schutzfunktion. In Vitrinen hinter Glas wurden kuriose Artefakte bewahrt. Raritäten aus der Natur wie das kleine Nilkrokodil wurden in Flüssigkeiten eingelegt und in verschlossenen Präparategläsern sichtbar konserviert.
								</div>
							</div>
						</li>
						<li class="exhibition-location">
							<div>
								<h2 class="exhibition-location-teaser">Glas erstaunt!</h2>
								<div class="exhibition-location-date">13.5. - 20.11.2022</div>
								<div class="exhibition-location-institution">Schlossmuseum Arnstadt</div>
								<div class="exhibition-location-text">
									Der Fokus der Ausstellung in Arnstadt liegt auf höﬁ schen Luxusobjekten. Dazu zählen die erstaunliche Zitrone aus venezianischem Glas, geschnittene und geschliffene Pokale, Spiegel und Kronleuchter. Eine Besonderheit stellen die Glasminiaturen der Puppenstadt Mon plaisir dar. Ein zweiter Themenschwerpunkt liegt auf frühneuzeitlichem Laborglas.
								</div>
							</div>
							<div class="exhibition-location-image">
								<img class="zitrone" src="/img/Zitrone.jpg" alt="Glas erstaunt!"/>
							</div>
						</li>
					</ul>
				</div>

				<h1 class="exhibition-title-second">{exhibition.title}</h1>
				<div class="exhibition-introduction">
					<div>
						<ParsedText text={exhibition.description} />
					</div>
				</div>

				{ exhibition.boardThumbnails.length === 0 ||
					<div class="exhibition-boards">
					<h2>Ausstellungstafeln</h2>
						<ExhibitionBoardThumbnails thumbnails={exhibition.boardThumbnails} />
					</div> }
			</div>
		:	<div class="exhibition-overview">Leider sind derzeit keine Details zur Ausstellung verfügbar.</div>
	);
};
