import { JSX }		from "preact";
import { Link }		from "preact-router/match";

import * as common	from "@glas/shared/common";

type CollectionOverviewProps = Readonly<{
	collections:	ReadonlyArray<common.Collection> | null,
	path:			string,
}>;

export const CollectionOverview = (props: CollectionOverviewProps):JSX.Element => {
	const collections:ReadonlyArray<common.Collection> | null = props.collections;

	return (
		<div class="collection-overview">
			<h1>Kollektionen</h1>
			{ collections !== null
			?	<ul class="collection-items">
				{collections.map((collection:common.Collection) => (
					<li class="collection-item">
						<Link activeClassName="active" href={"/collection/" + window.encodeURIComponent(collection.id)} >
							<div class="image-border collection-item-image">
								<img alt={collection.title} src={"img/355/" + window.encodeURIComponent(collection.image)} class="image"/>
							</div>
							<span class="collection-item-title">{collection.title}</span>
							<span class="collection-item-subtitle">{collection.subtitle}</span>
						</Link>
					</li>))}
				</ul>
			:	<div>Leider sind derzeit keine Kollektionen verfügbar.</div>
		}
		</div>
	);
};
