import { JSX }	from "preact";

import * as common	from "@glas/shared/common";

import {
	checkBox,
	rangeInputTwoDecimals,
	select,
} from "@glas/frontend/pages/search/searchInput";

type AdvancedSearchProps = Readonly<{
	dropDownTexts:	common.DropDownTexts,
	searchOptions:	common.ExtendedSearchOptions,
	more:			boolean,
}>;

export const AdvancedSearch = (props:AdvancedSearchProps):JSX.Element => {
	return (
		<div class={`search-more ${props.more ? "visible" : ""}`}>
			<div class="search-col">
				{rangeInputTwoDecimals("Höhe", "cm", "heightFrom", "heightTo",
					props.searchOptions.heightFrom, props.searchOptions.heightTo)}
				{rangeInputTwoDecimals("Wandstärke", "mm", "widthFrom", "widthTo",
					props.searchOptions.widthFrom, props.searchOptions.widthTo)}
				{rangeInputTwoDecimals("Masse", "g", "weightFrom", "weightTo",
					props.searchOptions.weightFrom, props.searchOptions.weightTo)}
				{rangeInputTwoDecimals("Füllvolumen", "ml", "volumeFrom", "volumeTo",
					props.searchOptions.volumeFrom, props.searchOptions.volumeTo)}
			</div>
			<div class="search-col">
				{select("color", props.searchOptions.color, props.dropDownTexts.colors, "Farbe")}
				{select("fluorescenceColor", props.searchOptions.fluorescenceColor,
					props.dropDownTexts.fluorescenceColors, "Fluoreszenz-Farbe")}
				{checkBox("raman", props.searchOptions.raman, "Raman Spektroskopie verfügbar")}
				{checkBox("uvvis", props.searchOptions.uvvis, "UV-Vis verfügbar")}
			</div>
		</div>
	);
};
