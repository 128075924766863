import { JSX }			from "preact";

import * as common		from "@glas/shared/common";

import { ParsedText }	from "@glas/frontend/components/ParsedText";

type IntroductionProps = Readonly<{
	introduction:	ReadonlyArray<common.IntroductionParagraph> | null,
	path:			string,
}>;

export const Introduction = (props: IntroductionProps):JSX.Element => {
	const introduction:ReadonlyArray<common.IntroductionParagraph> | null = props.introduction;
	return (
		<div class="introduction">
			<h1>Das Glas der Schwarzburger – Einführung</h1>
			<ul>
			{ introduction !== null && introduction.map((paragraph:common.IntroductionParagraph) =>
				<li class="columns">
					{ paragraph.image !== null && paragraph.imageText !== null && paragraph.imageText !== ""
						? <div class="column-icons">
								<img src={"/img/" + window.encodeURIComponent(paragraph.image)} alt={paragraph.imageText} />
								{
									// never shown, but would be a nice feature
									false && <h2>{paragraph.imageText}</h2>
								}
						</div>
						: <div class="column-icons"></div>
					}
					<div class="paragraph">
						{paragraph.headline !== null && paragraph.headline !== "" &&
							<h2>{paragraph.headline}</h2>
						}
						<div class="column-main">
							<ParsedText text={paragraph.text} />
						</div>
					</div>
				</li>
			)}
			</ul>
		</div>
	);
};
