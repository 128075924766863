import { JSX }			from "preact";
import { useState }		from "preact/hooks";

import * as common		from "@glas/shared/common";

import * as actions		from "@glas/frontend/actions";
import * as searchUtil	from "@glas/frontend/pages/search/searchUtil";

import {
	getCategories,
	getObjectDescriptionsForCategory,
	getTypesForObjectDescription,
}	from "@glas/frontend/util";

import {
	rangeInputFourDigits,
	select,
	textInput,
} from "@glas/frontend/pages/search/searchInput";

type MainSearchProps = Readonly<{
	dropDownTexts:	common.DropDownTexts,
	searchOptions:	common.ExtendedSearchOptions,
}>;

export const MainSearch = (props:MainSearchProps):JSX.Element => {
	const [ objectDescs, setObjectDescs ]		= useState<ReadonlyArray<string>>([]);
	const [ types, setTypes ]					= useState<ReadonlyArray<string>>([]);

	return (
		<div class="search-main">
			<div class="search-col">
				{textInput("Volltextsuche", "item", props.searchOptions.item)}
				{select("institution", props.searchOptions.institution,
					props.dropDownTexts.institutions, "Institution")}
				{select("locations", props.searchOptions.locations,
					props.dropDownTexts.locations, "Herstellungsort")}
				{rangeInputFourDigits("Datierung", "", "datingFrom", "datingTo", props.searchOptions.datingFrom, props.searchOptions.datingTo)}
			</div>
			<div class="search-col">
				{
					categorySelect(
						"category",
						props.searchOptions.category,
						getCategories(props.dropDownTexts.categories),
						"Sachgruppe",
						(newValue:string) => {
							// TODO factor could be a function SearchOptions => string => SearchOptions
							actions.setSearchOption("category",		searchUtil.parseStringOrNull(newValue));
							actions.setSearchOption("objectDesc",	null);
							actions.setSearchOption("type",			null);
							setObjectDescs(
								newValue === "" ? [] :
								getObjectDescriptionsForCategory(props.dropDownTexts.categories, common.Category(newValue))
							);
							setTypes([]);
						}
					)
				}
				{
					categorySelect(
						"objectDesc",
						props.searchOptions.objectDesc,
						objectDescs,
						"Objektbezeichnung",
						(newValue:string) => {
							// TODO factor could be a function SearchOptions => string => SearchOptions
							actions.setSearchOption("objectDesc",	searchUtil.parseStringOrNull(newValue));
							actions.setSearchOption("type",			null);
							setTypes(
								newValue === "" ? [] :
								getTypesForObjectDescription(props.dropDownTexts.categories, common.Objectdesc(newValue))
							);
						}
					)
				}
				{
					categorySelect(
						"type",
						props.searchOptions.type,
						types,
						"Typ",
						(newValue:string) => {
							actions.setSearchOption("type",			searchUtil.parseStringOrNull(newValue));
						}
					)
				}
				{
					select(
						"chemicalGlassType",
						props.searchOptions.chemicalGlassType,
						props.dropDownTexts.chemicalGlasTypes,
						"Chemischer Glastyp"
					)
				}
			</div>
		</div>
	);
};

const categorySelect = (name:keyof common.SearchOptions, value:string|null, texts:ReadonlyArray<string>, caption:string, func:(value:string) => void):JSX.Element =>
	<div class="search-form-unit">
		<label class="search-form-label search-form-unit-col1" for={name}>{caption}</label>
		<div class="search-form-unit-col2">
			<select
				id={name}
				class="search-form-input select"
				name={name}
				value={value ?? ""}
				disabled={texts.length === 0}
				onChange={(ev) => func(ev.currentTarget.value)}
			>
				<option></option>
				{ texts.map((text:string) => <option value={text}>{text}</option>)}
			</select>
			<div class="search-form-unit-col3"></div>
		</div>
	</div>;
