import { Branded }	from "ts-base/branded";

// this is a tree built from ObjectCategoryItemSql
export type CategoryList		= Record<Category,ObjectCategory>;
export type ObjectCategory		= Record<Objectdesc,ObjectDescription>;
export type ObjectDescription	= ReadonlyArray<Type>;

export type Category	= Branded<string,"Category">;
export const Category	= Branded.brand<Category>;

export type Objectdesc	= Branded<string,"Objectdesc">;
export const Objectdesc	= Branded.brand<Objectdesc>;

export type Type		= Branded<string,"Type">;
export const Type		= Branded.brand<Type>;

export type DropDownTexts = Readonly<{
	categories:			CategoryList,
	chemicalGlasTypes:	ReadonlyArray<string>,
	locations:			ReadonlyArray<string>,
	institutions:		ReadonlyArray<string>,
	colors:				ReadonlyArray<string>,
	fluorescenceColors:	ReadonlyArray<string>,
}>;

export type IntroductionParagraph = Readonly<{
	headline:	string|null,
	image:		string|null,
	imageText:	string|null,
	text:		string,
}>;

//-------------------------------------------------------------------
//	OBJECT
//-------------------------------------------------------------------

type InventoryIdType = `${"A_" | "F_" | "R_" | "S_"}${string}`;

export type InventoryId		= Branded<InventoryIdType,"InventoryId">;
export const InventoryId	= Branded.brand<InventoryId>;

export const parseInventoryId = (raw:string):InventoryId|null => {
	// TODO factor do we have to trim in here in all cases?
	const trimmed	= raw.trim();
	const valid		= /^[A|F|R|S]_/.test(trimmed);
	return valid ? trimmed as InventoryId : null;
};

export type ObjectImages = Readonly<{
	images: ReadonlyArray<string>,
	ramanImages: ReadonlyArray<string>,
	uvvisImages: ReadonlyArray<string>,
}>;

export type ObjectThumbnail = Readonly<{
	inventoryId: InventoryId,
	titleShort: string,
	datingShort: string,
	imageFile: string|null,
}>;

export type ObjectRaw = Readonly<{
	inventoryId:			InventoryId,
	title:					string,
	description:			string,
	institution:			string,
	relatedTo:				string|null,
	setDesc:				string|null,
	category:				string,
	objectDesc:				string,
	type:					string,
	material:				string|null,
	condition:				string|null,
	munsell:				string|null,
	descriptionBy:			string|null,
	descriptionDate:		string|null,
	placeOfManufacture:		string|null,
	persons:				string|null,
	period:					string|null,
	measurements:			string|null,
	raman:					boolean,
	uvvis:					boolean,
	chemicalGlassType:		string|null,
	color:					string|null,
	fluorescenceColor:		string|null,
	restaurations:			string|null,
}>;

export type ObjectDisplayed = ObjectRaw & ObjectImages;
export type ExtendedObject = ObjectDisplayed & ObjectThumbnail;

//-------------------------------------------------------------------
//	COLLECTION
//-------------------------------------------------------------------

export type CollectionId	= Branded<number,"CollectionId">;
export const CollectionId	= Branded.brand<CollectionId>;

export const parseCollectionId = (raw:string):CollectionId|null => {
	// TODO factor do we have to trim in here in all cases?
	const trimmed	= raw.trim();
	const collectionId = parseInt(trimmed);
	return isNaN(collectionId)
		? null
		: collectionId as CollectionId;
};

export type Collection = Readonly<{
	id: CollectionId,
	title: string,
	subtitle: string,
	descShort: string,
	description: string,
	image: string,
	inventoryIds: ReadonlyArray<InventoryId>,
}>;

// contains object thumbnails instead of just object ids
export type ExtendedCollection = Omit<Collection, "inventoryIds"> & Readonly<{
	objectThumbnails: ReadonlyArray<ObjectThumbnail>,
}>;

//-------------------------------------------------------------------
//	EXHIBITION
//-------------------------------------------------------------------

export type ExhibitionBoardId	= Branded<number,"ExhibitionBoardId">;
export const ExhibitionBoardId	= Branded.brand<ExhibitionBoardId>;

export const parseExhibitionBoardId = (raw:string):ExhibitionBoardId|null => {
	// TODO factor do we have to trim in here in all cases?
	const trimmed	= raw.trim();
	const exhibitionBoardId = parseInt(trimmed);
	return isNaN(exhibitionBoardId)
		? null
		: exhibitionBoardId as ExhibitionBoardId;
};

export type Exhibition = Readonly<{
	title:				string,
	description:		string,
}>;

export type ExtendedExhibition = Exhibition & Readonly<{
	boardThumbnails:	ReadonlyArray<ExhibitionBoardThumbnail>,
}>;

export type ExhibitionBoardThumbnail = Readonly<{
	boardId:		ExhibitionBoardId,
	titleShort:		string,
	image:			string,
}>;

export type ExhibitionBoard = Readonly<{
	boardId:	ExhibitionBoardId,
	title:		string,
	text:		string,
	objectIds:	ReadonlyArray<InventoryId>,
	pdfFile:	string,
}>;

// contains object thumbnails instead of just object ids
export type ExtendedExhibitionBoard = Omit<ExhibitionBoard, "objectIds"> & Readonly<{
	objectThumbnails: ReadonlyArray<ObjectThumbnail>,
}>;

//-------------------------------------------------------------------
//	SEARCH
//-------------------------------------------------------------------

export type SearchOptions = Readonly<{
	item:				string|null,
	institution:		string|null,
	datingFrom:			number|null,
	datingTo:			number|null,
	locations:			string|null,
	category:			string|null,
	objectDesc:			string|null,
	type:				string|null,
	chemicalGlassType:	string|null,
	heightFrom:			number|null,
	heightTo:			number|null,
	widthFrom:			number|null,
	widthTo:			number|null,
	weightFrom:			number|null,
	weightTo:			number|null,
	volumeFrom:			number|null,
	volumeTo:			number|null,
	color:				string|null,
	fluorescenceColor:	string|null,
	raman:				boolean|null,
	uvvis:				boolean|null,
}>;

export type ExtendedSearchOptions = SearchOptions & {
	currentResultPage: number,
};

export const emptySearchOptions:ExtendedSearchOptions	= {
	item:				null,
	institution:		null,
	datingFrom:			null,
	datingTo:			null,
	locations:			null,
	category:			null,
	objectDesc:			null,
	type:				null,
	chemicalGlassType:	null,
	heightFrom:			null,
	heightTo:			null,
	widthFrom:			null,
	widthTo:			null,
	weightFrom:			null,
	weightTo:			null,
	volumeFrom:			null,
	volumeTo:			null,
	color:				null,
	fluorescenceColor:	null,
	raman:				null,
	uvvis:				null,
	currentResultPage:	1,
};

export type SearchResults = Readonly<{
	results:	number,
	data:		ReadonlyArray<ObjectThumbnail>,
}>;

export type ExtendedSearchResults = SearchResults & Readonly<{
	// number of pages into which results are split
	resultPages:	number,
	offset:			number,
	searchOptions:	ExtendedSearchOptions,
}>;
