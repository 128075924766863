
import * as primitive	from "@glas/shared/primitive";

export const getParameter = (name:string):string|null =>
	parameters().get(name);

export const parameters = ():URLSearchParams =>
	new URL(window.location.href).searchParams;

// the only "good" reason to use the sort parameter is when we use the generated url as a cache key
export const queryString	= (parameters:Record<string,string>, sort:boolean = false):string	=> {
	const query:Array<string> = [];
	for (const key in parameters) {
		if (parameters.hasOwnProperty(key)) {
			query.push(encodeURIComponent(key) + "=" + encodeURIComponent(parameters[key]));
		}
	}
	if (sort)	{
		query.sort();
	}
	return (query.length !== 0 ? "?" + query.join("&") : "");
};

export const naturalOrNull	= (it:string|null):number|null =>
	it !== null ? primitive.parseNaturalNumber(it) : null;
