import { render }	from "preact";

import { Endo }			from "ts-base/function";

import { App }					from "@glas/frontend/App";
import { initialModel, Model }	from "@glas/frontend/model";
import * as actions				from "@glas/frontend/actions";


window.addEventListener("DOMContentLoaded", () => {
	let model = initialModel;

	const display	= () =>
		render(<App model={model}/>, document.body);

	display();

	actions.setRefresh(
		(change:Endo<Model>):void	=> {
			model = change(model);
			display();
		}
	);

	actions.boot();
});
