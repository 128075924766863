import { JSX }			from "preact";
import { Match, Link }	from "preact-router/match";

import * as actions		from "@glas/frontend/actions";

type HeaderProps = Readonly<unknown>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Header = (props:HeaderProps):JSX.Element =>
	<div class="header">
		<div class="header-logo">
			<Link activeClassName="active" href="/">
				<img alt="Glas Logo" src="/img/LogoGlas.png" />
			</Link>
		</div>
		<div class="header-main">
			<div class="header-main-title">
				<span class="header-main-title">Material, Funktion und Bedeutung</span>
				<span class="header-main-subtitle">ein Forschungsprojekt der Universität Gießen</span>
			</div>
			<div class="header-main-navigation">
				<nav>
					<ul class="header-main-navigation-items">
						<Match path="/">
							{	// eslint-disable-next-line @typescript-eslint/no-explicit-any
								( match:any ) => match.path !== "/" &&
								<li class="exhibition"><Link activeClassName="active" href="/exhibition"
									onClick={() => actions.resetExhibitionBoard()}>Ausstellung</Link></li>
							}
						</Match>
						<li><Link activeClassName="active" href="/introduction">Projekt</Link></li>
						<li><Link activeClassName="active" href="/collections"
							onClick={() => actions.resetCollection()} >Kollektionen</Link></li>
						<li><Link activeClassName="active" href="/objects"
							onClick={() => actions.resetObject()}>Objekte</Link></li>
						<li><Link activeClassName="active" href="/search"
							onClick={() => actions.resetSearch()}>Objektsuche</Link></li>
					</ul>
				</nav>
			</div>
			<Match path="/">
				{	// eslint-disable-next-line @typescript-eslint/no-explicit-any
					( match:any ) => match.path === "/" &&
					<div class="exhibition-prime">
						<Link activeClassName="active" href="/exhibition"
							onClick={() => actions.resetExhibitionBoard()}>
							<img alt="Ausstellungen" src="img/Zitrone.png" class="exhibition-prime-image"/>
							<div class="exhibition-prime-bg-cover"></div>
							<div class="exhibition-prime-bg">
								<span class="exhibition-prime-text">Das Glas der <br/> Schwarzburger<br/></span>
								<span class="exhibition-prime-subtext">- Die Ausstellung -</span>
							</div>
						</Link>
					</div>
				}
			</Match>
		</div>
	</div>;
