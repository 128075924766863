import * as common	from "@glas/shared/common";

export type Model	=  Readonly<{
	dropDownTexts:		common.DropDownTexts|null,
	introduction:		ReadonlyArray<common.IntroductionParagraph>|null,
	object:				common.ExtendedObject|null,
	objectThumbnails:	ReadonlyArray<common.ObjectThumbnail>,
	objects:			common.ExtendedSearchResults|null,
	collection:			common.ExtendedCollection|null,
	collections:		ReadonlyArray<common.Collection>|null,
	exhibition:			common.ExtendedExhibition|null,
	exhibitionBoard:	common.ExtendedExhibitionBoard|null,
	searchOptions:		common.ExtendedSearchOptions,
	searchResults:		common.ExtendedSearchResults|null,
}>;

export const initialModel:Model	= {
	dropDownTexts:		null,
	introduction:		null,
	object:				null,
	objectThumbnails:	[],
	objects:			null,
	collection:			null,
	collections:		null,
	exhibition:			null,
	exhibitionBoard:	null,
	searchOptions:		common.emptySearchOptions,
	searchResults:		null,
};
