import { JSX }				from "preact";
import { useEffect }		from "preact/hooks";

import * as common			from "@glas/shared/common";

import * as actions			from "@glas/frontend/actions";
import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { ParsedText }		from "@glas/frontend/components/ParsedText";

type ExhibitionBoardProps = Readonly<{
	exhibitionBoard:	common.ExtendedExhibitionBoard|null,
	path:				string,
}>;

type ExtendedProps = ExhibitionBoardProps & Readonly<{ boardId: string }>;

export const ExhibitionBoard = (props: ExhibitionBoardProps):JSX.Element => {
	const exhibitionBoard	= props.exhibitionBoard;

	// preact-router does not allow us to include path parameters in our props
	const extendedProps	= props as ExtendedProps;
	const boardId		= common.parseExhibitionBoardId(extendedProps.boardId);

	useEffect(
		() => {
			if (boardId !== null)   actions.getExtendedExhibitionBoard(boardId);
		},
		[ boardId ]
	);

	return (
		exhibitionBoard !== null
		?	<div class="exhibition-detail">
				<h1>{exhibitionBoard.title}</h1>
				<div class="exhibition-description adoptFormat">
					<ParsedText text={exhibitionBoard.text} />
					<a class="link-to-pdf" href={`/pdf/${window.encodeURIComponent(exhibitionBoard.pdfFile)}`} target="_blank" >
						<span>Tafel als PDF-Datei</span>
					</a>
				</div>
				{ exhibitionBoard.objectThumbnails.length !== 0 &&
					<div>
						<h2>Objekte</h2>
						<ObjectThumbnails thumbnails={exhibitionBoard.objectThumbnails} />
					</div> }
			</div>
		: <div class="exhibition-detail">Die angefrage Ausstellungstafel ist nicht verfügbar.</div>
	);
};
