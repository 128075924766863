import { JSX }					from "preact";

import * as Nullables			from "ts-base/nullable";

import * as common				from "@glas/shared/common";

import * as actions				from "@glas/frontend/actions";
import { formatInventoryId }	from "@glas/frontend/util";

type ParsedTextProps = Readonly<{
	text: string,
}>;

const URL_DNB = "https://d-nb.info/gnd/";

export const ParsedText = ({ text }:ParsedTextProps):JSX.Element => (
	<div>
		{ text.split("\n\n").map(
			(paragraph:string) => parseTextForLinks(paragraph)
		)}
	</div>
);

const parseTextForLinks	= (text:string|null):JSX.Element|null	=> {
	// text within two # -> like #A_K3000#
	const regexInventoryId	= /#(?<inventoryId>[A|F|R|S]_[^#]+)#/g;
	// text within two *, containing [ and ] -> like *Samuel Schwarz [5461842X]*
	const regexPerson		= /\*((?<personName>[^*[\]]*)\[(?<personLink>[^*[\]]*)\][^*[\]]*)\*/g;
	// text within two @_@ -> like @https://test.de@
	const regexExternalLink = /@_@(?<link>((?!@_@).)+)@_@/g;
	// text within two §_§ -> like @https://test.de@
	const regexFormattedText = /§_§(?<formattedText>((?!§_§).)+)§_§/g;

	const regex:string =
		regexInventoryId.source		+ "|" +
		regexExternalLink.source	+ "|" +
		regexPerson.source			+ "|" +
		regexFormattedText.source;

	return text === null
	? null
	: <p class="adoptFormat">{
		regexFindAll(regex, text).map(found =>
			"match" in found
				? makeLink(found.match) : found.text
		)
	}</p>;
};

const makeLink	= (match:RegExpExecArray):JSX.Element|null	=> {
	const parse	= (it:string):string|null =>
		Nullables.lift(nonBlank)(match.groups?.[it] ?? null);

	const getInventoryId = ():common.InventoryId|null => {
		const inventoryIdStr	= parse("inventoryId");
		const inventoryId = Nullables.then(inventoryIdStr)(common.parseInventoryId);
		if (inventoryIdStr !== null && inventoryId === null) {
			console.log(`not a valid inventory id: ${inventoryIdStr}`);
		}
		return inventoryId;
	};

	const getFormattedText = () => {
		const formattedText	= parse("formattedText");
		const formatted = formattedText?.split("_") ?? null;
		if (formattedText !== null && (formatted === null || formatted.length < 2)) {
			console.log(`not a valid formatted text: ${formattedText}`);
			return null;
		}
		return {
			text: formatted?.[1],
			class: formatted?.[0],
		};
	};

	const inventoryId		= getInventoryId();
	const personName		= parse("personName");
	const personLink		= parse("personLink");
	const formattedText		= getFormattedText();
	const urlPersonLink		=
		personLink					=== null		? null			:
		personLink.substring(0, 8)	=== "https://"	? personLink	:
		URL_DNB + personLink;

	const link				= parse("link");

	const linkElement:JSX.Element|null =
		inventoryId !== null ?
			<a
				class="textlink"
				href={`/object/${window.encodeURIComponent(inventoryId)}`}
				onClick={() => { actions.resetObject(); }}
			>{formatInventoryId(inventoryId)}</a>
			:

		personName !== null && urlPersonLink !== null ?
			<a class="textlink" href={urlPersonLink} target="blank">{personName}</a>
			:

		link !== null ?
			<a class="textlink" href={link} target="blank">{link}</a>
			:

		formattedText !== null ?
			<span class={`${formattedText.class ?? ""}`}>{formattedText.text ?? ""}</span>
			:

		null;

	return linkElement;
};

const nonBlank = (it:string):string|null => {
	const trimmed = it.trim();
	return trimmed !== "" ? trimmed : null;
};

//-----------------------------------------------------------------------------

type MatchPart	=
	{ text:string			}	|
	{ match:RegExpExecArray	};

/** returns MatchParts of alternating type: first a text, then a match, and always a text at the end */
const regexFindAll = (re:string, text:string):ReadonlyArray<MatchPart> => {
	const regex		= new RegExp(re, "g");
	const results	= [];
	let pos	= 0;
	for (;;) {
		const match:RegExpExecArray|null	= regex.exec(text);
		if (match !== null) {
			// push the text before the match
			results.push({ text: text.slice(pos, match.index) });
			pos	= regex.lastIndex;
			// then push the match
			results.push({ match });
		}
		else {
			// now push the text after the last match
			results.push({ text: text.slice(pos) });
			return results;
		}
	}
};
