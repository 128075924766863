import { JSX }		from "preact";
import { Link }		from "preact-router/match";

import * as common	from "@glas/shared/common";

type ExhibitionBoardThumbnailsProps = Readonly<{
	thumbnails: ReadonlyArray<common.ExhibitionBoardThumbnail> | undefined,
}>;

export const ExhibitionBoardThumbnails = (props:ExhibitionBoardThumbnailsProps):JSX.Element =>
	<ul class="thumbnails">
		{ props.thumbnails?.map((thumbnail:common.ExhibitionBoardThumbnail) =>
			<li class="thumbnail-item">
				<Link activeClassName="active" href={"/exhibitionboard/" + window.encodeURIComponent(thumbnail.boardId)} >
					<div class="image-border thumbnail-image">
						<img alt={thumbnail.titleShort} src={"/img/exhibition/180/" + window.encodeURIComponent(thumbnail.image)} class="image"/>
					</div>
					<div>
						<span class="thumbnail-description">{thumbnail.titleShort}</span>
					</div>
				</Link>
			</li>)
		}
	</ul>;
