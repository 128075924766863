import { JSX }					from "preact";
import { Match, Link }			from "preact-router/match";
// import { useEffect, useState }	from "preact/hooks";

import * as common				from "@glas/shared/common";

import { classes }				from "@glas/frontend/util";
import { ScrollToTopButton }	from "@glas/frontend/components/ScrollToTopButton";

type FooterProps = Readonly<{
	searchResults:	common.ExtendedSearchResults|null,
}>;

export const Footer = (props:FooterProps):JSX.Element => {

	/*
	const [lastPosition, setLastPosition] = useState(0);

	const savePosition = () => {
		setLastPosition(window.pageYOffset);
		console.log(lastPosition, window.pageYOffset)
	};

	useEffect(() => {
		window.addEventListener("scroll", savePosition);
		return () => window.removeEventListener("scroll", savePosition);
	}, []);
	*/

	return (
		<Match path="/">
			{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				( match:any ) => {
					const footerStyle =
							match.path === "/search" && props.searchResults === null	? "searchempty"
						:	match.path === "/imprint"									? "imprint"
						:	match.path === "/"											? "start"
						:	"default";
					const footerClass = classes("footer",`footer-${footerStyle}`);

					return (
						<div class={footerClass}>
							<ScrollToTopButton/>
							<div class="footer-brightbar">
								<img class="footer-logo" alt="BmBF Logo" src="/img/bmbf-logo.svg" />
							</div>
							<nav class="footer-darkbar">
								<ul class="footer-navigation">
									<li><Link activeClassName="active" href="/contact">Kontakt</Link></li>
									<li><Link activeClassName="active" href="/imprint">Impressum</Link></li>
								</ul>
							</nav>
						</div>
					);
				}
			}
		</Match>
	);
};
