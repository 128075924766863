export const parseStringOrNull	= (it:string):string|null	=> {
	const out	= it.trim();
	return out !== "" ? out : null;
};

// TODO factor tighten this? disallow negative values?
export const parseIntOrNull = (it:string):number|null => {
	const out	= parseInt(it.trim());
	return Number.isNaN(out) ? null : out;
};

export const parseFloatOrNull = (it:string):number|null => {
	const out	= parseFloat(it.replace(",",".").trim());
	return Number.isNaN(out) ? null : out;
};

/*
used for checkboxes in search:
True has to be inverted to null, so the search option is not sent to backend. If the value of not checked checkbox "xy" was false, the search would return all objects were xy="false" instead of ignoring value of xy
*/
export const invertBooleanOrNull	= (it:boolean|null):boolean|null	=>
	it === true ? null : it === false ? true : true;
