import { JSX }				from "preact";

import * as common			from "@glas/shared/common";

import { ObjectThumbnails }	from "@glas/frontend/components/ObjectThumbnails";
import { Pagination }		from "@glas/frontend/components/Pagination";
import * as actions			from "@glas/frontend/actions";

type ResultsProps = Readonly<{
	searchResults:		common.ExtendedSearchResults,
}>;

export const Results = (props:ResultsProps):JSX.Element =>
	<div class="search-results">
		<Pagination
			searchResults={props.searchResults}
			searchAction={actions.getSearchResult}
		/>
		<ObjectThumbnails thumbnails={props.searchResults.data} />
		{ props.searchResults.data.length > 0 && <Pagination
			searchResults={props.searchResults}
			searchAction={actions.getSearchResult}
		/> }
	</div>;
