import { JSX }					from "preact";
import { Router }				from "preact-router";

import { Model }				from "@glas/frontend/model";

import { Header }				from "@glas/frontend/components/Header";
import { Footer }				from "@glas/frontend/components/Footer";

import { CollectionOverview }	from "@glas/frontend/pages/collection/CollectionOverview";
import { CollectionDetail }		from "@glas/frontend/pages/collection/CollectionDetail";
import { Contact }				from "@glas/frontend/pages/contact/Contact";
import { ExhibitionOverview }	from "@glas/frontend/pages/exhibition/ExhibitionOverview";
import { ExhibitionBoard }		from "@glas/frontend/pages/exhibition/ExhibitionBoard";
import { Imprint }				from "@glas/frontend/pages/imprint/Imprint";
import { Introduction }			from "@glas/frontend/pages/introduction/Introduction";
import { ObjectOverview }		from "@glas/frontend/pages/object/ObjectOverview";
import { ObjectDetail }			from "@glas/frontend/pages/object/ObjectDetail";
import { Search }				from "@glas/frontend/pages/search/Search";
import { Start }				from "@glas/frontend/pages/start/Start";


type AppProps = Readonly<{
	model:Model,
}>;

export const App = (props:AppProps):JSX.Element => {

	const handleRoute = ():void => {
		// hack: scroll page to top when changed
		window.scrollTo({ top: 0 });
	};

	return (
		<>
			<Header />
			<div class="main">
				<Router onChange={ handleRoute }>
					<Start				path="/" />
					<Introduction		path="/introduction" introduction={props.model.introduction} />
					<CollectionOverview	path="/collections" collections={props.model.collections} />
					<CollectionDetail	path="/collection/:collectionId" collection={props.model.collection} />
					<ObjectOverview		path="/objects" objects={props.model.objects} />
					<ObjectDetail		path="/object/:inventoryId" obj={props.model.object}/>
					<ExhibitionOverview	path="/exhibition" exhibition={props.model.exhibition} />
					<ExhibitionBoard	path="/exhibitionboard/:boardId" exhibitionBoard={props.model.exhibitionBoard} />
					<Search				path="/search"
										dropDownTexts={props.model.dropDownTexts}
										searchResults={props.model.searchResults}
										searchOptions={props.model.searchOptions} />
					<Contact			path="/contact" />
					<Imprint			path="/imprint" />
				</Router>
			</div>
			<Footer searchResults={props.model.searchResults}/>
		</>
	);
};
