import { JSX }				from "preact";
import { useState }			from "preact/hooks";

import * as common			from "@glas/shared/common";

import * as actions			from "@glas/frontend/actions";
import { MainSearch }		from "@glas/frontend/pages/search/MainSearch";
import { AdvancedSearch }	from "@glas/frontend/pages/search/AdvancedSearch";
import { Results }			from "@glas/frontend/pages/search/Results";

type SearchProps = Readonly<{
	path:			string,
	dropDownTexts:	common.DropDownTexts|null,
	searchOptions:	common.ExtendedSearchOptions,
	searchResults:	common.ExtendedSearchResults|null,
}>;

export const Search = (props:SearchProps):JSX.Element => {
	const [ moreButton, setMoreButton ] = useState(false);

	/*
	useEffect(
		() => {
			actions.setSearchMore(moreButton); //save button state to model
		},
		[ moreButton ]
	);
	*/

	const handleSubmit = (ev:Event) => {
		ev.preventDefault();
		actions.getSearchResult({ ...props.searchOptions, currentResultPage: 1 });
	};

	return (
		<div class="search">
			<h1>Objektsuche</h1>

			{props.dropDownTexts !== null
			?	<form class="search-form" onSubmit={handleSubmit}>
					<MainSearch
						dropDownTexts={props.dropDownTexts}
						searchOptions={props.searchOptions}/>
					<div class="search-more-button">
						<button class="search-button button-tertiary more text-link" type="button"
							onClick={() => setMoreButton(!moreButton)}
						>
							<span>weitere Merkmale</span>
							<div class={"fa " + (moreButton ? "fa-chevron-down" : "fa-chevron-right")}></div>
						</button>
					</div>
					<AdvancedSearch
						dropDownTexts={props.dropDownTexts}
						searchOptions={props.searchOptions}
						more={moreButton}/>
					<div class="search-buttons">
						<button class="search-button button-primary submit" type="submit">
							<div class="fa fa-search"></div>
							<span>Suchen</span>
						</button>
						<button
							onClick={() => actions.resetSearchOptions()}
							class="search-button button-secondary delete text-link"
							type="button">
							<span>Eingaben löschen</span>
						</button>
					</div>
				</form>
			:	<div>Derzeit steht leider keine Suchfunktion zur Verfügung.</div>}
			{ props.searchResults !== null &&
				<Results searchResults={props.searchResults} />
			}
		</div>
	);
};
