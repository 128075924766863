import { JSX }	from "preact";

type StartProps = Readonly<{
	path: string,
}>;


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Start = (path: StartProps):JSX.Element =>
	<div class="start">
		<h1>Glas. Vom faszinierenden Material der Eliten zum Alltagsobjekt</h1>
		<span class="start-text">
			Glas spielte eine bedeutende Rolle in der europäischen Frühen Neuzeit. Glas ist durchsichtig und zugleich dauerhaft, es kann Licht reflektieren und bündeln, es kann gefärbt sein, und es lässt sich dekorieren. Zudem ist es hitzebeständig, geruch- und geschmacklos. So fand Glas in verschiedenen Feldern des Lebens Anwendung: in Alltag und Festkultur, Alchemie, Medizin und Astronomie, als Teil von Inneneinrichtungen, als optisches Instrument, als Teil von Tischdekoration und Trinkkultur und als Sammlungsgegenstand. Glas hat jedoch auch einen großen Nachteil und das ist seine Zerbrechlichkeit. Glas als Material war damit ein Symbol für die Vergänglichkeit des menschlichen Lebens. Um 1600 noch den Eliten vorbehalten, entwickelte sich Glas bis zum Ende des 18. Jahrhunderts zum Massenprodukt.
		</span>
	</div>;
